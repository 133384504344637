<template>
	<div class="vld-parent">
	<loading :active="loading" color="#D9F7C5" loader="bars"/>
	</div>
	<div class="p-grid crud-demo">
		<div class="p-col-12">
			<div class="card" id="xyz">			
				<Toast/>
				<DataTable ref="dt" :value="inmuebles" class="p-datatable-customers" v-model:selection="selectedInmuebles" dataKey="id_inmueble" :paginator="true" :rows="10" :filters="filters"
							paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
							currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingDataTable">
					<template #header>
							<h5 class="p-m-0">Estados de Cuenta</h5>
							<div class="p-field p-formgrid p-grid">
								<div class="p-field p-col-3">
									<div class="p-fluid">
										<label for="inmueble">Inmueble</label>
										<div class="p-fluid p-inputgroup">
										<InputText size="135" id="inmueble" type="text" v-model="InmuebleDisplay" disabled/>																												
										<Button v-if="mostrarBotonBusquedaInmueble" icon="pi pi-search" @click="buscarInmueble"/>							
										<Button v-else icon="pi pi-times" class="p-button-warning" @click="buscarInmueble"/>																
										</div>
									</div>
								</div>																
								<div class="p-field p-col">
									<div class="p-fluid">
										<label for="desde">Mes</label>
										<Calendar id="desde" v-model="desde" view="month" dateFormat="mm/yy" :key="periodoUpdate" :showIcon="true" @date-select="fetchData"/>
									</div>
								</div>
								<div class="p-field p-col">
									<div class="p-fluid">
										<label for="id_moneda">Moneda</label>
										<Dropdown v-model="idMoneda" :options="lkpMonedas" optionLabel="moneda" optionValue="id_moneda" placeholder="Seleccionar"/>
									</div>
								</div>
								<div class="p-field p-col p-d-flex p-ai-end">
									<div class="p-fluid">
									<Button label="Generar" class="p-button-primary" @click="fetchData" />								
									</div>
								</div>
								<div class="p-field p-col p-d-flex p-ai-end">
									<div class="p-fluid">
										<Button label="Exportar" icon="pi pi-file-excel" class="p-button-success" @click="exportarXLSResumenPorInmueble"/>
									</div>
								</div>														
								<div class="p-field p-col p-d-flex p-ai-end">
									<div class="p-fluid">
										<Button label="Enviar" icon="pi pi-envelope" class="p-button-secondary" @click="envioNotificacionesDlg=true" :disabled="!puedeEnviarEmails"/>								
									</div>
								</div>														
							</div>	
					</template>
					<Column field="display" header="Inmueble" :sortable="true" headerStyle="width: 60%">
					</Column>
					<Column field="saldo_inicial" :sortable="true" header="Saldo Inicial" headerStyle="width: 10%; text-align: right" bodyStyle="text-align: right">
						<template #body="slotProps">							
							<!-- {{formatCurrency(slotProps.data.id_moneda, slotProps.data.saldo_inical)}}				 -->
							{{ slotProps.data.saldo_inicial.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
						</template>	
					</Column>			
					<Column field="cargos" :sortable="true" header="Cargos" headerStyle="width: 10%; text-align: right" bodyStyle="text-align: right">
						<template #body="slotProps">							
							<!-- {{formatCurrency(slotProps.data.id_moneda, slotProps.data.cargos)}}				 -->
							{{ slotProps.data.cargos.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
						</template>	
					</Column>	
					<Column field="creditos" :sortable="true" header="Créditos" headerStyle="width: 10%; text-align: right" bodyStyle="text-align: right">
						<template #body="slotProps">							
							{{ slotProps.data.creditos.toLocaleString('en-US', {minimumFractionDigits: 2}) }}			
						</template>	
					</Column>	
					<Column field="saldo_final" :sortable="true" header="Saldo Final" headerStyle="width: 10%; text-align: right" bodyStyle="text-align: right">
						<template #body="slotProps">							
							{{ slotProps.data.saldo_final.toLocaleString('en-US', {minimumFractionDigits: 2}) }}			
						</template>	
					</Column>																		
					<Column headerStyle="width: 3rem">
						<template #body="slotProps">							
							<Button text="Ver" icon="pi pi-eye" class="p-button-rounded p-button-success p-mr-2" @click="verEstadoCuenta(slotProps.data)" />							
						</template>
					</Column>					
				</DataTable>
			</div>
			<MdsBuscarInmueble :mostrar="dlgBuscarInmueble" @cancelar="cerrarDlgInmueble" @seleccionar="selInmueble"/>

		<Dialog v-model:visible="estadoCuentaDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" :closable="false">
			<template #header>
				<div class="p-fluid">											
				<div class="p-fluid p-m-1">
					<h4 >
					Estado de Cuenta
					</h4>											
				</div>				
				<div class="p-fluid p-grid">				
					<div class="p-col-8">					
						<h5 >{{ estadoCuentaInmueble.display }}</h5>
					</div>
					<div class="p-formgroup-inline">
						<div class="p-field">
							<label for="desde">Desde</label>
							<Calendar id="desdeIndividual" v-model="desdeIndividual" view="month" dateFormat="mm/yy" :showIcon="true" @date-select="fetchEstadoCuentaXInmueble"/>
						</div>
						<div class="p-field">
							<label for="hasta">Hasta</label>
							<Calendar id="hastaIndividual" v-model="hastaIndividual" view="month" dateFormat="mm/yy" :showIcon="true" @date-select="fetchEstadoCuentaXInmueble"/>						
						</div>
						<div class="p-field p-d-flex p-as-end" >					
							<Button class="p-button-success" label="Descargar" icon="pi pi-file-pdf"  @click="getEstadoCuenta(estadoCuentaInmueble)"/>	
						</div>
						<div class="p-field p-d-flex p-as-end" >					
							<Button class="p-button-secondary" label="Enviar" icon="pi pi-envelope" @click="enviarEstadoCuenta()"/>	
						</div>
					</div>							
				</div>
				</div>
			</template>
			<div :style="{height: '425px'}">
			<DataTable ref="dt" :value="estadoCuentaInmueble.movimientos" class="p-datatable-sm"  dataKey="id" 
            :paginator="false" 
			:scrollable="true"
			:loading="loadingEstadoCuenta">
					<template #empty>
						No hay movimientos para este período
					</template>
					<template #header>
					<div class="p-field p-formgrid p-grid">											
						<div class="p-field p-col">
							<div class="p-fluid">
								<label for="saldo_inicial">Saldo Inicial</label>
								<label class="p-ml-2" for="saldo_inicial">{{ estadoCuentaInmueble.saldo_inicial.toLocaleString('en-US', {minimumFractionDigits: 2}) }}</label>
							</div>
						</div>		
						<div class="p-field p-col">
							<div class="p-fluid">
								<label for="saldo_final">Saldo Final</label>
								<label class="p-ml-2" for="saldo_final">{{ estadoCuentaInmueble.saldo_final.toLocaleString('en-US', {minimumFractionDigits: 2}) }}</label>
							</div>
						</div>
					</div>						
				</template>
				<Column field="fecha" header="Fecha" headerStyle="width: 5%; text-align: left" bodyStyle="text-align: left">
				</Column>	
				<Column field="tipo" header="Tipo" headerStyle="width: 10%; text-align: left" bodyStyle="text-align: left">
				</Column>							
				<Column field="documento" header="Documento" headerStyle="width: 10%; text-align: left" bodyStyle="text-align: left">
				</Column>					
				<Column field="referencia" header="Referencia" headerStyle="width: 30%; text-align: left" bodyStyle="text-align: left">
				</Column>									
				<Column field="cargo" header="Cargo" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right">
					<template #body="slotProps">										
						<div v-if="slotProps.data.cargo != 0" class="p-text-bold p-text-right" style="text-align: right">
							{{ slotProps.data.cargo.toLocaleString('en-US', {minimumFractionDigits: 2}) }}
						</div>
						<div v-else class="p-text-center">
							-
						</div>						
					</template>	
				</Column>	
				<Column field="credito" header="Crédito" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right">
					<template #body="slotProps">	
						<div v-if="slotProps.data.credito != 0" class="p-text-bold p-text-right" style="text-align: right">
							{{ slotProps.data.credito.toLocaleString('en-US', {minimumFractionDigits: 2}) }}			
						</div>												
						<div v-else>
							-
						</div>							
					</template>	
				</Column>	
				<Column field="saldo" header="Saldo" headerStyle="width: 5%; text-align: right" bodyStyle="text-align: right">
					<template #body="slotProps">
						<div class="p-text-bold">							
						{{ slotProps.data.saldo.toLocaleString('en-US', {minimumFractionDigits: 2}) }}			
						</div>
					</template>	
				</Column>																								
			</DataTable>
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="estadoCuentaDlg=false"/>
				</div>
			</template>
		</Dialog>

		<Dialog v-model:visible="envioNotificacionesDlg" :style="{width: '1150px', height: '600px'}"  :showHeader="true" :modal="true" >
			<template #header>
				<div class="p-fluid">											
					<div class="p-fluid p-m-1">
						<h4 >
						Envío de Estados de Cuenta por Email
						</h4>				
					</div>				
				</div>
			</template>
			<div :style="{height: '425px'}">
				<DataTable ref="dt" :value="inmuebles" class="p-datatable-customers" v-model:selection="selectedInmuebles" dataKey="id_inmueble" :paginator="true" :rows="10" :filters="filters"
								paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown" :rowsPerPageOptions="[5,10,25]"
								currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords}" :loading="loadingDataTable">
					<Column selectionMode="multiple" headerStyle="width: 3rem"/>
					<Column field="display" header="Inmueble" :sortable="true" headerStyle="width: 60%"/>
				</DataTable>
			</div>
			<template #footer>
				<div class="p-field p-col">
					<Button label="Cancelar" icon="pi pi-times" class="p-button-text" @click="envioNotificacionesDlg=false"/>
					<Button label="Enviar" icon="pi pi-send" class="p-button-text" @click="enviarAInmueblesSeleccionados" :disabled="!enviarEmails"/>
				</div>
			</template>
		</Dialog>

		<Dialog v-model:visible="enviandoEmailsDlg" :showHeader="true" :modal="true" :closable="false">			
			<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
				<div class="p-d-flex p-ai-center p-jc-center">
					<i class="pi pi-send p-m-2" style="font-size: 2rem"></i>
				</div>
				<div class="flex align-items-center justify-content-center mt-5">				
				<ProgressBar :value="secuencia">										
					<div class="font-light">
					Enviando {{secuencia}} de {{selectedInmuebles.length}}
					</div>
				</ProgressBar>
				</div>
			</div>
		</Dialog>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import MdsBuscarInmueble from '../../components/MdsBuscarInmueble.vue'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import MdsService from '../../service/mds.service';
import GrlService from '../../service/grl.service';
import { read, utils, writeFileXLSX } from 'xlsx';

export default {
	data() {
		return {
			inmuebles: null,
			complejo: this.$store.state.auth.currentAppCtx.complejo,
			deleteDialog: false,
			selectedInmuebles: null,
			InmuebleDisplay: '(Todos)',
			dlgBuscarInmueble : false,
			estadoCuentaInmueble: null,
			estadoCuentaDlg: false,
			idClasificacion: "*", //idclasificacion,
			nivel1: "*", //nivel1, 
			nivel2: "*", //nivel2, 
			nivel3: "*", //nivel3, 
			nivel4: "*", //nivel4, 
			nivel5: "*", //nivel5, 
			idinmueble: null, 
			selectedInmuebles: null,
			lkpMonedas: this.$store.state.auth.currentAppCtx.Monedas,
			idMoneda: this.$store.state.auth.currentAppCtx.monedaBase.id_moneda, //idmoneda, 
			desde: new Date(),
			hasta: new Date(),	
			desdeIndividual: null,
			hastaIndividual: null,
			periodoUpdate: 0,
			ocultarColumnas: {
				nivel1: false,
				nivel2: true,
				nivel3: false,
				nivel4: true,
				nivel5: true
			},
			inmueble: null,
			loading: false,
			loadingEstadoCuenta: false,
			loadingDataTable: false,				
			envioNotificacionesDlg: false,
			enviandoEmailsDlg: false,
			secuencia: 1,
			filters: {},
			submitted: false
		}
	},
	components: {
		Loading, MdsBuscarInmueble
	},		
	mounted() {
		this.fetchData();
	},
	computed: {
		mostrarBotonBusquedaInmueble(){
			return (!this.idinmueble);
		},
		puedeEnviarEmails() {
			if(this.inmuebles)
			{
				return this.inmuebles.length > 0;
			} else {
				return false;
			}
		},
		enviarEmails() {
			if(this.selectedInmuebles)
			{
				return this.selectedInmuebles.length > 0;
			} else {
				return false;
			}
		}				
	},
	methods: {
		actualizarPeriodo(periodo)
		{
			this.desde = periodo.desde;
			this.hasta = periodo.hasta;
			this.periodoUpdate++;
		},		
		formatCurrency(moneda, value) {
			if(value)
				return value.toLocaleString('en-US', {style: 'currency', currency: moneda});
			return;
		},	
		buscarInmueble() {
			if(!this.idinmueble){								
				this.dlgBuscarInmueble = true;
			} else {
				this.InmuebleDisplay = '(Todos)';
				this.idinmueble = null;			
			}
		},
		selInmueble(Inmueble)
		{
			this.idinmueble = Inmueble.id_inmueble;			
			this.InmuebleDisplay = Inmueble.display;

			this.dlgBuscarInmueble = false;	

			this.fetchData();
		},
		cerrarDlgInmueble()
		{
			this.dlgBuscarInmueble = false;			
		},
		delay(time) {
			return new Promise(resolve => setTimeout(resolve, time));
		},	
		async enviarEstadoCuenta()
		{
			try
			{
				this.loading = true;
				await MdsService.enviarEmailEstadoCuenta(this.$store.state.auth.currentAppCtx,
					{
						id_empresa: `${this.$store.state.auth.currentAppCtx.id_referencia}`,
						id_moneda: `${this.idMoneda}`,
						id_cta_habiente_info: `${this.inmueble.id_cta_habiente_info}`,
						fecha_ini: new Date(this.desdeIndividual.getFullYear(), this.desdeIndividual.getMonth(), 1),
						fecha_fin: new Date(this.hastaIndividual.getFullYear(), this.hastaIndividual.getMonth()+1, 1, -1)						
					},
					{}
				)
				this.loading = false;
			}
			catch(error) {
				this.loading = false;
				console.log(error);
			}
		},
		async enviarAInmueblesSeleccionados()
		{
			this.envioNotificacionesDlg	= false;
			try
			{
				this.enviandoEmailsDlg = true;
				this.secuencia = 1;
				for(var i = 0; i < this.selectedInmuebles.length; i++)
				{				
					await MdsService.enviarEmailEstadoCuenta(this.$store.state.auth.currentAppCtx,
						{
							id_empresa: `${this.$store.state.auth.currentAppCtx.id_referencia}`,
							id_moneda: `${this.idMoneda}`,
							id_cta_habiente_info: `${this.selectedInmuebles[i].id_cta_habiente_info}`,
							fecha_ini: new Date(this.desde.getFullYear(), this.desde.getMonth(), 1),
							fecha_fin: new Date(this.desde.getFullYear(), this.desde.getMonth()+1, 1, -1)	
						},
						{}
					)
					this.secuencia++;
				}
				this.secuencia = 1;
				this.enviandoEmailsDlg = false;
			}
			catch(error) {
				console.log(error);
			}
		},
		async fetchEstadoCuentaXInmueble(){
			this.loadingEstadoCuenta = true;
			try{
				let filtro = {
						IdInmueble: `${this.inmueble.id_inmueble}`,
						IdMoneda: `${this.idMoneda}`,  
						desde: this.desdeIndividual, 
						hasta: this.hastaIndividual  	
				};
				
				let data = await MdsService.getInmueblesEstadoCuenta(this.$store.state.auth.currentAppCtx, filtro);
				this.estadoCuentaInmueble = data;

				if((this.estadoCuentaInmueble.movimientos) && (this.estadoCuentaInmueble.movimientos.length > 0)) {
					this.estadoCuentaInmueble.saldo_final = this.estadoCuentaInmueble.movimientos[this.estadoCuentaInmueble.movimientos.length -1].saldo;
				}
				
				this.loadingEstadoCuenta = false;
			}
			catch(data)
			{
				this.loadingEstadoCuenta = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
			}	
		},
		async verEstadoCuenta(registro) {
			this.loading = true;
			try
			{
				this.desdeIndividual = this.desde;
				this.hastaIndividual = this.desde;
				this.inmueble = registro;
				await this.fetchEstadoCuentaXInmueble();
				this.loading = false;
				this.estadoCuentaDlg = true;
			} catch (err) {
				this.loading = false;
				this.estadoCuentaDlg = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000});
			}
		},
		getEstadoCuenta(Inmueble)
		{
			this.loading = true;

			let Data = {
				reporte: `cxcEstadoCuenta`,
				params: [					
					{ParamName: "idCtaHabienteInfo", Valor: Inmueble.id_cta_habiente_info},
					{ParamName: "FechaIni", Valor: new Date(this.desdeIndividual.getFullYear(), this.desdeIndividual.getMonth(), 1)},					
					{ParamName: "FechaFin", Valor: new Date(this.hastaIndividual.getFullYear(), this.hastaIndividual.getMonth()+1, 1, -1)},
					{ParamName: "idEmpresa", Valor: this.$store.state.auth.currentAppCtx.id_referencia},
					{ParamName: "idMoneda", Valor: this.idMoneda},				
				]
			}

			this.estadoCuentaDlg = false;

			GrlService.GenerarRpt(this.$store.state.auth.currentAppCtx, Data).then(
				response => {
					//Create a Blob from the PDF Stream
					this.loading = false;
					const file = new Blob(
					[response.data], 
					{type: 'application/pdf'});
					//Build a URL from the file
					const fileURL = URL.createObjectURL(file);	

					var link = document.createElement('a');
					link.href = fileURL;
					link.download = `EstadoCuenta(${Inmueble.display}).pdf`;
					link.dispatchEvent(new MouseEvent('click'));
				}				
			).catch(
				data => {
					this.anularDialog = false;
					this.mov = {};
					this.$toast.add({severity:'error', summary: 'Error', detail: data})
				}
			)					
		},
		async exportarXLSResumenPorInmueble()
		{
			this.loading = true;
			try {
				var rows;

				rows = this.inmuebles.map(
					row => ({							
						"Inmueble": row.display,
						"Saldo Inicial": row.saldo_inicial,
						"Cargos": row.cargos,
						"Créditos": row.creditos,
						"Saldos Final": row.saldo_final
					})
				);

				const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio",
  					"Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
				];
				var mes = monthNames[this.desde.getMonth()];
				var anio = this.desde.getFullYear();
				
  				const ws = utils.json_to_sheet(rows);
				var wb = utils.book_new();
				utils.book_append_sheet(wb, ws, `${this.idMoneda}-EstadoCuentaResumen`);
				writeFileXLSX(wb, `ResumenEstadoCuenta${mes}${anio}.xlsx`);

				this.loading = false;
			} catch (error)
			{
				this.loading = false;				
				this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000})								
			}	
		},		
		fetchData(){
			this.loadingDataTable = true;

			let filtro = {
					idClasificacion: this.idClasificacion,
					nivel1: `${this.nivel1}`,
					nivel2: `${this.nivel2}`,
					nivel3: `${this.nivel3}`, 
					nivel4: `${this.nivel4}`, 
					nivel5: `${this.nivel5}`,  
					IdInmueble: `${this.idinmueble?this.idinmueble:''}`,
					IdMoneda: `${this.idMoneda}`,  
					desde: this.desde, 
					hasta: this.hasta  	
			};

			MdsService.getInmueblesResumenSaldos(this.$store.state.auth.currentAppCtx, filtro)		
			.then(
				data => {
					this.inmuebles = data;
					this.loadingDataTable = false;
				}
			).catch(
				data => {
					this.loadingDataTable = false;
					this.$toast.add({severity:'error', summary: 'Error', detail: data, life: 3000})
				}
			);	
		},
		exportCSV() {
			this.$refs.dt.exportCSV();
		}
	}
}
</script>

<style scoped lang="scss">
.table-header {
	display: flex;
	justify-content: space-between;
}

.product-image {
	width: 100px;
	box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
	width: 150px;
	margin: 0 auto 2rem auto;
	display: block;
}

.confirmation-content {
	display: flex;
	align-items: center;
	justify-content: center;
}

.product-badge {
	border-radius: 2px;
	padding: .25em .5rem;
	text-transform: uppercase;
	font-weight: 700;
	font-size: 12px;
	letter-spacing: .3px;

	&.status-instock {
		background: #C8E6C9;
		color: #256029;
	}

	&.status-outofstock {
		background: #FFCDD2;
		color: #C63737;
	}

	&.status-lowstock {
		background: #FEEDAF;
		color: #8A5340;
	}
}
/* Responsive */
.p-datatable-customers .p-datatable-tbody > tr > td .p-column-title {
	display: none;
}

@media screen and (max-width: 960px) {
	::v-deep(.p-datatable) {
		&.p-datatable-customers {
			.p-datatable-thead > tr > th,
			.p-datatable-tfoot > tr > td {
				display: none !important;
			}

			.p-datatable-tbody > tr {
				border-bottom: 1px solid var(--surface-d);

				> td {
					text-align: left;
					display: block;
					border: 0 none !important;
					width: 100% !important;
					float: left;
					clear: left;
					border: 0 none;

					&:last-child {
						text-align: center;
					}

					.p-column-title {
						padding: .4rem;
						min-width: 30%;
						display: inline-block;
						margin: -.4rem 1rem -.4rem -.4rem;
						font-weight: bold;
					}

					.p-progressbar {
						margin-top: .5rem;
						display: inline-block;
						width: 60%;
					}

					.p-rating {
						display: inline-block;
					}
				}
			}

			.p-datatable-tbody > tr.p-rowgroup-footer{
				display: flex;
			}
		}
	}
}

::v-deep(.p-toolbar) {
	flex-wrap: wrap;
	.p-button {
		margin-bottom: .25rem;
	}
}
</style>
